// Axios setup
import VueAxios from "vue-axios";
import authHeader from "../services/auth-header";
import Vue from "vue";
import store from "@/store";
import router from "@/router";
// import { saveRedirectionIntoStorage } from "@/util/helpers/LoginRedirectionHelpers/LoginRedirectionHelpers";

const axios = require("axios");
Vue.use(VueAxios, axios);

const BACKEND_URLS = {
    LOCAL_HOST: "http://127.0.0.1:5000/",
    Doha: "https://iwaa.dohalaw.net/",
    stageiwaa: "https://stageback.eservices-saudiiwaa.com/",
    prod: "https://back.eservices-saudiiwaa.com/"
};

const FRONT_URLS = {
    LOCAL_HOST: "http://localhost:8081/",
    Doha: "https://saudiiwaa.dohalaw.net/",
    stageiwaa: "https://stage.eservices-saudiiwaa.com/",
    prod: "https://eservices-saudiiwaa.com/"
};

Vue.prototype.$baseURL = BACKEND_URLS.prod;
Vue.prototype.$frontURL = FRONT_URLS.prod;

const ENDPOINT_BASE_API = BACKEND_URLS.prod + "api";

window.axios = require("axios");
axios.defaults.baseURL = ENDPOINT_BASE_API;
axios.interceptors.request.use(function (config) {
    config.headers = authHeader();
    return config;
});
axios.interceptors.response.use(
    function (response) {
        return response;
    },
    function (error) {
        console.log(error)

        if (error.response && error.response.status == 401) {
            console.log(router)
                ; store
                    .dispatch("auth/logout")
                    .then(() => {
                        // const prevPath = router.history._startLocation ?? undefined;
                        // saveRedirectionIntoStorage(prevPath);
                        router.push({ path: `/login` });
                    })
                    .catch((err) => console.log(err));
        }
        return Promise.reject(error);
    }
);
